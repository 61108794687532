<template>
  <div>数据统计</div>
</template>

<script>
export default {
  name: "CoworkingCampusOADataStatisticsScroll"
}
</script>

<style scoped>

</style>
