<!-- 校园OA > 分组管理 -->
<template>
    <div class="group-manage">
        <div class="page-main-container">
            <div class="filter-wrap">
                <expand-filter
                    :formData="formData"
                    margin-bottom="0"
                    margin-top="0"
                    @clickBtn="handlerFilterBtnClick"
                    @changeBtnFormType="changeBtnFormType"
                ></expand-filter>
                <div class="button-line" v-has-permi="['groupOa:add']"></div>
                <div>
                    <el-button v-has-permi="['groupOa:add']" type="primary" @click="handlerGroupAdd">添加分组</el-button>
                </div>
            </div>

            <table-data
                id="table"
                ref="table"
                v-loading="loadingTable"
                :config="tableConfig"
                :tableData="tableData">
                <template v-slot:operation="slotData">
                    <el-button v-has-permi="['oaGroup:edit']" type="text" @click="handlerGroupEdit(slotData.data)">编辑</el-button>
                    <el-button v-has-permi="['oaGroup:del']" type="text" @click="handlerGroupDel(slotData.data)">删除</el-button>
                </template>
            </table-data>
        </div>

        <!-- 添加/编辑分组弹窗 -->
        <dialog-wrapper :dialogObj="addDialogObj" @handleClose="handlerAddDialogClose">
            <el-form ref="addForm" class="common-form" :model="addForm" :rules="addRules" label-width="100px">
                <el-form-item label="分组名称" prop="groupName">
                    <el-input class="input-width-medium" v-model="addForm.groupName" placeholder="请输入分组名称"></el-input>
                </el-form-item>
                <div :style="{'text-align': 'right'}">
                    <el-button type="enquiry" @click="handlerAddDialogClose">取消</el-button>
                    <el-button type="primary" @click="handlerAddDialogSave">确定</el-button>
                </div>
            </el-form>
        </dialog-wrapper>
    </div>
</template>

<script>
import { DialogWrapper } from 'common-local';
import ExpandFilter from "@/components/scrollWrapper/Sub/ExpandFilter"
import TableData from "@/components/scrollWrapper/Sub/TableData"
import onResize from "@/mixins/onResize";
import { CampusOAModel } from "@/models/CampusOA";
import { mapState } from 'vuex';

export default {
    name: 'CoworkingCampusOAGroupManageScrollWrapper',
    mixins: [onResize],
    components: {
        ExpandFilter,
        TableData,
        DialogWrapper
    },
    data() {
        return {
            campusOAModel: null,
            // 头部筛选
            formData: {
                data: [
                    {
                        type: 'input',
                        label: '',
                        value: '',
                        placeholder: '分组名称',
                        key: 'groupName'
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: '查询',
                        fn: 'primary',
                    },
                ],
            },
            listQuery: {
                schoolId: '',
                groupName: ''
            },
            loadingTable: false,
            // 表格配置
            tableConfig: {
                thead: [
                    {
                        label: "名称",
                        prop: "groupName",
                        align: "center",
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "创建人",
                        prop: "createByName",
                        align: "center"
                    },
                    {
                        label: "更新时间",
                        prop: "updateTime",
                        align: "center",
                        type: "function",
                        callBack: (row) => {
                            if (row.updateTime) {
                                return row.updateTime.slice(0, 16)
                            }
                        }
                    },
                    {
                        label: "操作",
                        type: "slot",
                        align: 'center',
                        slotName: 'operation',
                        labelWidth: '220',
                        className: 'text-spacing-reduction'
                    }
                ],
                rowkey: 'id',
                check: false,
                height: '',
            },
            // 表格数据
            tableData: [],
            // 添加/编辑分组弹窗相关
            addDialogObj: {
                title: '添加分组',
                dialogVisible: false,
                width: 'auto'
            },
            addForm: {
                schoolId: '',
                id: '',
                groupName: ''
            },
            addRules: {
                groupName: [{ required: true, message: '请输入分组名称', trigger: 'blur' }],
            }
        }
    },
    computed: {
        ...mapState({
            schoolId: state => state.schoolId
        })
    },
    watch: {},
    created() {
        this.init()
    },
    mounted() {},
    methods: {
        /**
         * 初始化相关
         * init 初始化入口
         * getList 获取列表
         * handleRes 接口请求结果处理
         */
        // 初始化入口
        init() {
            this.campusOAModel = new CampusOAModel()
            this.listQuery.schoolId = this.schoolId
            this.addForm.schoolId = this.schoolId
            this.getList()
        },
        // 获取列表
        async getList() {
            this.loadingTable = true
            let res = await this.campusOAModel.getSchoolFormGroup(this.listQuery)
            this.handleRes(res,() => {
                console.log("[getList]res:", res)
                this.tableData = res.data.data;
            })
            this.loadingTable = false
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === '200') {
                fn()
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg)
            }
        },

        /**
         * 页面按钮点击事件
         * handlerFilterBtnClick 筛选组件按钮点击事件
         * handlerGroupAdd 添加分组
         * handlerGroupEdit 编辑分组
         * handlerGroupDel 删除分组
         */
        // 筛选组件按钮点击事件
        handlerFilterBtnClick(ev) {
            switch (ev.item.fn) {
                case 'primary':
                    for (let item of this.formData.data) {
                        this.listQuery[item.key] = item.value
                    }
                    this.getList()
                    break;
                default:
                    break;
            }
        },
        // 添加分组
        handlerGroupAdd() {
            this.addForm.id = ''
            this.addForm.groupName = ''
            this.addDialogObj.title = '添加分组'
            this.handlerAddDialogShow()
        },
        // 编辑分组
        handlerGroupEdit(row) {
            console.log("[handlerGroupEdit]row:", row)
            this.addForm.id = row.id
            this.addForm.groupName = row.groupName
            this.addDialogObj.title = '编辑分组'
            this.handlerAddDialogShow()
        },
        // 删除分组
        handlerGroupDel(row) {
            this.$confirm('此操作将永久删除该分组, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let arr = [],
                    obj = {
                        id: row.id,
                        name: row.groupName
                    };
                arr.push(obj)
                this.campusOAModel.schoolFormGroupDelete(arr).then(async (res) => {
                    this.handleRes(res, () => {
                        this.$message.success('删除成功')
                        this.getList()
                    })
                })
            })
        },

        /**
         * handlerAddDialogShow 添加分组弹窗展示
         * handlerAddDialogClose 添加分组弹窗关闭
         * handleAddDialogSave 添加分组保存
         */
        // 添加分组弹窗展示
        handlerAddDialogShow() {
            this.addDialogObj.dialogVisible = true
        },
        // 添加分组弹窗关闭
        handlerAddDialogClose() {
            this.addDialogObj.dialogVisible = false
        },
        // 添加分组保存
        handlerAddDialogSave() {
            this.$refs.addForm.validate((valid) => {
                if (valid) {
                    this.campusOAModel.schoolFormGroupSave(this.addForm).then((res) => {
                        this.handleRes(res, () => {
                            this.$message.success(`${this.addDialogObj.title}成功`)
                            this.handlerAddDialogClose()
                            this.getList()
                        })
                    })
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.group-manage {
    margin: 10px 10px 10px 0;
}

.filter-wrap {
    display: flex;
    text-align: center;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 10px 0;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 10px;
    .expand-filter {
        padding: 0;
    }
}
</style>
